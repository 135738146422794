h2.backlog-title {
  display: none;
}

[data-coreui-theme=dark] {
  --cui-light-rgb: 40, 45, 55
}

[data-coreui-theme=dark] .header-brand {
  color: rgba(255, 255, 255, 0.83)
}

[data-coreui-theme=dark] .table-light {
  --cui-table-bg: 40, 45, 55;
  --cui-table-color: 221, 227, 228;
  --cui-table-border-color: 50, 58, 73;
  border-color: rgb(var(--cui-table-border-color))
}

[data-coreui-theme=dark] .table-light th {
  background-color: rgb(var(--cui-table-bg))
}

[data-coreui-theme=dark] .table-success {
  --cui-table-bg: 27, 70, 28;
  --cui-table-color: 221, 227, 228;
  --cui-table-border-color: 50, 58, 73;
  border-color: rgb(var(--cui-table-border-color))
}

[data-coreui-theme=dark] .table-success td {
  background-color: rgb(var(--cui-table-bg))
}

[data-coreui-theme=dark] .table-danger {
  --cui-table-bg: 95, 28, 28;
  --cui-table-color: 221, 227, 228;
  --cui-table-border-color: 50, 58, 73;
  border-color: rgb(var(--cui-table-border-color))
}

[data-coreui-theme=dark] .table-danger td {
  background-color: rgb(var(--cui-table-bg))
}

@media print {
  body {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .table > :not(caption) > * > * {
    padding: 2px !important;
  }
  #root {margin:0 !important;}
  h1:first-child, h1 + h2 {page-break-before: avoid !important;}
  .tab-pane {
    display: block !important;
    opacity: 1 !important;
  }
  h2.backlog-title {
    display: block;
    page-break-before: always !important;
  }
  .card {
    border-width: 0 !important;
  }
  .header, .footer, .card-header {
    display: none !important;
  }
  td, th {
    border: 1px solid black !important;
  }
}
